import elementVisibility from "./../elementVisibility"

const attachMobileNavToggle = () => {
  const mobileNavToggle = document.getElementById("mobile-nav-toggle")
  const mobileNavDropdown = document.getElementById("mobile-nav-dropdown")
  const siteMainContent = document.querySelector("main")

  if (mobileNavToggle && mobileNavDropdown) {
    mobileNavToggle.addEventListener("click", (ev) => {
      ev.preventDefault()

      if (elementVisibility.elementIsHidden(mobileNavDropdown)) {
        elementVisibility.showElement(mobileNavDropdown)
        elementVisibility.hideElement(siteMainContent)
      } else {
        elementVisibility.hideElement(mobileNavDropdown)
        elementVisibility.showElement(siteMainContent)
      }
    })
    // This ensures that clicking a link on the mobile nav dropdown hides
    // the dropdown, for the case where the link is an anchor tag on the page
    const childLinks = mobileNavDropdown.querySelectorAll("a")
    childLinks.forEach(link => {
      link.addEventListener("click", () => {
        elementVisibility.showElement(siteMainContent)
        setTimeout(() => {
          elementVisibility.hideElement(mobileNavDropdown)
        }, 1000)
      })
    })
  }
}

document.addEventListener("DOMContentLoaded", attachMobileNavToggle)
