const elementIsHidden = (element) => {
  const classes = Array.from(element.classList)
  if (classes.indexOf("dn") >= 0) {
    return true
  } else {
    return false
  }
}

const showElements = (elements) => {
  elements.forEach(showElement)
}

const hideElements = (elements) => {
  elements.forEach(hideElement)
}

const showElement = (element) => {
  element.style.display = "block"
  element.classList.remove("dn")
  element.classList.add("db")
}

const hideElement = (element) => {
  element.style.display = "none"
  element.classList.remove("db")
  element.classList.add("dn")
}

exports.elementIsHidden = elementIsHidden
exports.showElement     = showElement
exports.hideElement     = hideElement
exports.showElements    = showElements
exports.hideElements    = hideElements
